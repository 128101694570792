// Home.tsx
import React from "react";
import useAuthCheck from "../hooks/userAuthCheck";
import CourseCard from "../components/cards/CourseCard";

const Home: React.FC = () => {
  const isAuthenticated = useAuthCheck();
  return (
    <div>
      <div className="container mt-4">
        <div className="flex row justify-center">
          <div className="col-lg-4 mr-5">
            <CourseCard
              imageUrl="https://via.placeholder.com/600x400"
              title="Welcome to Our Website"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor felis eu libero..."
              url="/login"
            />
          </div>
          <div className="col-lg-4 mr-5">
            <CourseCard
              imageUrl="https://via.placeholder.com/600x400"
              title="Welcome to Our Website"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor felis eu libero..."
              url="/login"
            />
          </div>
          <div className="col-lg-4 mr-5">
            <CourseCard
              imageUrl="https://via.placeholder.com/600x400"
              title="Welcome to Our Website"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor felis eu libero..."
              url="/login"
            />
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
      <div>
        {isAuthenticated ? (
          <div>
            {/* Render user details here */}
            <p>User details go here</p>
          </div>
        ) : (
          <div>
            <p>Please log in to see user details.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
