import { useEffect } from "react";
import { useAuth, AuthContextType } from "../context/AuthContext";

const useAuthCheck = () => {
  const { isAuthenticated } = useAuth() as AuthContextType;

  useEffect(() => {}, [isAuthenticated]);

  return isAuthenticated;
};

export default useAuthCheck;
