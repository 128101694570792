import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Button,
} from "@nextui-org/react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/dropdown";
import { Link, NavLink, Outlet } from "react-router-dom";
import { Switch } from "@nextui-org/react";
import { SunIcon, MoonIcon } from "@heroicons/react/solid";
import { useAuth } from "../../context/AuthContext";
import { useTheme } from "next-themes";
import Footer from "./Footer";
import Mobilenavbar from "./Mobilenavbar";

interface Menu {
  name: string;
  path: string;
}

const NavbarComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isAuthenticated, logout } = useAuth() || {}; // Get isAuthenticated from useAuth
  const userName: string = "John Doe";

  const [mounted, setMounted] = useState<boolean>(false);
  const { theme, setTheme } = useTheme();
  const [checked, setChecked] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setMounted(true);
    if (theme === "dark") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [theme]);

  if (!mounted) return null;

  const handleChange = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    setChecked(!checked);
  };

  // Define the menu items for authenticated users and all users
  const menus: Menu[] = [
    { name: "Home", path: "/" },
    { name: "Dashboard", path: "/dashboard" },
    { name: "Explore", path: "/explore" },
  ];

  // Add "Settings" menu only if the user is authenticated
  if (isAuthenticated) {
    menus.push({ name: "Settings", path: "/settings" });
  }

  return (
    <>
      <div style={{ display: isVisible ? "block" : "none" }}>
        {/* Mobile Navbar */}
        <div className="md:hidden">
          <Mobilenavbar
            menus={menus}
            isAuthenticated={isAuthenticated}
            userName={userName}
            logout={logout}
            theme={theme}
            handleChange={handleChange}
            checked={checked}
          />
          <div className="p-5">
            <Outlet />
          </div>
        </div>

        {/* Desktop Navbar */}
        <div className="hidden md:block">
          <Navbar isBordered>
            <NavbarBrand>
              <NavLink to="/" className="text-xl font-bold">
                edorbit
              </NavLink>
            </NavbarBrand>
            <NavbarContent justify="start">
              {menus.map((menu) => (
                <NavbarItem key={menu.path}>
                  <NavLink to={menu.path} className="text-inherit">
                    {({ isActive }) => (
                      <NavbarItem isActive={isActive}>{menu.name}</NavbarItem>
                    )}
                  </NavLink>
                </NavbarItem>
              ))}
            </NavbarContent>
            <NavbarContent justify="end">
              {!isAuthenticated ? (
                <>
                  <NavLink to="/login" className="text-inherit">
                    {({ isActive }) => (
                      <NavbarItem isActive={isActive} key="/login">
                        <Button variant="flat" color="primary">
                          Login
                        </Button>
                      </NavbarItem>
                    )}
                  </NavLink>
                  <NavLink to="/signup" className="text-inherit">
                    {({ isActive }) => (
                      <NavbarItem isActive={isActive} key="/signup">
                        Signup
                      </NavbarItem>
                    )}
                  </NavLink>
                </>
              ) : (
                <div className="flex items-center space-x-2">
                  <span className="font-semibold">{userName}</span>
                  <Dropdown>
                    <DropdownTrigger>
                      <div className="flex items-center space-x-2 cursor-pointer">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3IklvtpbuEZpOHKUjrtNnpbrLA0Ci1QfIp_pti36gW70pnpO0yo7jIqbWm0FiN6gJsto&usqp=CAU" // Replace with actual profile picture URL
                          alt="Profile"
                          className="rounded-full h-8 w-8 object-cover"
                        />
                      </div>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownItem key="account">
                        <Link to="/settings" className="text-inherit">
                          Account Info
                        </Link>
                      </DropdownItem>
                      <DropdownItem key="logout">
                        <button className="text-red-600" onClick={logout}>
                          Logout
                        </button>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
              <Switch
                size="sm"
                isSelected={checked}
                onChange={handleChange}
                startContent={<MoonIcon />}
                endContent={<SunIcon />}
              />
            </NavbarContent>
          </Navbar>
          <div className="p-5">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NavbarComponent;
