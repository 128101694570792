import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChapterCard from "../components/cards/ChapterCard";
import axios from "..//config/axiosConfig";
import Loader from "../components/ui/Loader";
import { IChapter, ISubject } from "../data/ChaptersProps";

const Chapter = () => {
  const { subjectId } = useParams<{ subjectId: any }>();
  const [subject, setSubjectDetail] = useState<ISubject>();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (subjectId) {
      setLoader(true);
      axios
        .post<ISubject>("/chapters/by-subject", {
          subjectId: subjectId,
        })
        .then((response) => {
          setSubjectDetail(response.data);
        })
        .catch((error) => console.error("Error fetching data:", error))
        .finally(() => setLoader(false));
    }
  }, [subjectId]);

  if (loader) {
    return <Loader></Loader>;
  }

  return (
    <>
      {subject && subject.chapters.length > 0 ? (
        <div>
          <div className="container mx-auto mt-2 px-4">
            <h2 className="text-left mb-4 text-5xl">
              {subject.subjectName}{" "}
              <span className="text-teal-500 text-5xl">Chapters</span>
            </h2>
          </div>
          <div className="row justify-content-center grid sm:grid-cols-2 gap-4 lg:grid-cols-3">
            {subject &&
              subject.chapters.map((chapter, index) => (
                <ChapterCard
                  chapterId={chapter.chapterId}
                  chapterNumber={chapter.chapterNumber}
                  chapterName={chapter.chapterTitle}
                  chapterDesc={chapter.chapterDescription}
                  thumb={chapter.chapterThumbnail}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className="container mx-auto mt-2 px-4">
          <h2 className="text-left mb-4 text-5xl">
            No chapters found for this subject.
          </h2>
        </div>
      )}
    </>
  );
};

export default Chapter;
