import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import baseURL from "../config/axiosConfig";
import { useAuth } from "../context/AuthContext";
import toast from "react-hot-toast";

const Signup: React.FC = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState<string | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<string | null>(null);
  const [options1, setOptions1] = useState<{ id: number; label: string }[]>([]);
  const [options2, setOptions2] = useState<{ id: number; label: string }[]>([]);
  const [loading1, setLoading1] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [formError, setFormError] = useState<string>(""); // New state for form errors
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // New state for success popup
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const auth = useAuth();
  const setIsAuthenticated = auth?.setIsAuthenticated ?? (() => {});
  const isAuthenticated = auth?.isAuthenticated ?? false;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
      toast.error("Logout First", {
        duration: 4000,
        position: "bottom-center",
      });
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    // Fetch options for Class
    baseURL
      .get("/classes")
      .then((response) => {
        const classOptions = response.data.map((item: any) => ({
          id: item.classId,
          label: item.className,
        }));
        setOptions1(classOptions);
        setLoading1(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the class options!", error);
        setLoading1(false); // Stop loading even if there's an error
      });

    // Fetch options for School
    baseURL
      .get("/schools")
      .then((response) => {
        const schoolOptions = response.data.map((item: any) => ({
          id: item.id,
          label: item.schoolName,
        }));
        setOptions2(schoolOptions);
        setLoading2(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the school options!", error);
        setLoading2(false); // Stop loading even if there's an error
      });
  }, []);

  const toggleDropdown1 = () => {
    setIsOpen2(false);
    setIsOpen1(!isOpen1);
  };

  const closeDropdown1 = (option: { label: string }) => {
    setSelectedOption1(option.label);
    setIsOpen1(false);
  };

  const toggleDropdown2 = () => {
    setIsOpen1(false);
    setIsOpen2(!isOpen2);
  };

  const closeDropdown2 = (option: { label: string }) => {
    setSelectedOption2(option.label);
    setIsOpen2(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log("Submitting form...");

    const errors: string[] = [];

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@])[A-Za-z\d#@]{8,}$/;
    if (!passwordRegex.test(password)) {
      errors.push(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (# or @)."
      );
    }

    if (!selectedOption1) {
      errors.push("Please select a Class.");
    }

    if (errors.length > 0) {
      setFormError(errors.join(" "));
      console.log("Form errors:", errors);
      return;
    } else {
      setFormError("");
    }
    setIsSubmitting(true);
    // Prepare data to send
    const data = {
      name,
      email,
      phoneNumber: phoneNumber || null,
      password,
      classId:
        options1.find((option) => option.label === selectedOption1)?.id || 0,
      schoolId:
        options2.find((option) => option.label === selectedOption2)?.id || 0,
    };

    console.log("Form data:", data);

    try {
      // Make the POST request to the signup endpoint
      const response = await baseURL.post(
        "/auth/signup",
        {
          name,
          email,
          phoneNumber: phoneNumber || null,
          password,
          classId:
            options1.find((option) => option.label === selectedOption1)?.id ||
            0,
          schoolId:
            options2.find((option) => option.label === selectedOption2)?.id ||
            null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        // If signup is successful, navigate to the login page
        setIsSubmitting(false);
        toast.success("Signup successful!", {
          duration: 4000,
          position: "bottom-center",
        });
        setShowSuccessPopup(true); // If signup is successful, show the success popup
        navigate("");
      } else {
        // Handle unexpected status codes if needed
        setIsAuthenticated(false);
        setFormError("Unexpected response from server.");
      }
    } catch (error: any) {
      // Handle error response from the server
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setFormError(`${errorMessage}`);
      } else {
        setFormError("An error occurred while submitting the form.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange =
    (setState: React.Dispatch<React.SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setState(e.target.value);
    };

  const isSpace = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = /^[A-Za-z\s]*$/;

    if (regex.test(value)) {
      setName(value);
    }
  };

  const isMobile = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regexMob = /^[6789]\d*$/;

    if (value === "" || regexMob.test(value)) {
      setPhoneNumber(value);
    }
  };

  const isPasswordValid = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regexPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    setPassword(value);
    if (!regexPassword.test(value)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
    } else {
      setPasswordError("");
    }
  };

  return (
    <div className="h-[100vh] items-center flex justify-center px-5 lg:px-0">
      <div className="max-w-screen-xl bg-white border shadow sm:rounded-lg flex justify-center flex-1">
        <div className="flex-1 bg-blue-900 text-center hidden md:flex">
          <div
            className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(https://www.tailwindtap.com/assets/common/marketing.svg)`,
            }}
          ></div>
        </div>
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div className="flex flex-col items-center">
            <div className="text-center">
              <h1 className="text-2xl xl:text-4xl font-extrabold text-blue-900">
                Student Sign up
              </h1>
              <p className="text-[12px] text-gray-500">
                Hey enter your details to create your account
              </p>
            </div>
            <div className="w-full flex-1 mt-8">
              <form onSubmit={handleSubmit}>
                <div className="mx-auto max-w-xs flex flex-col gap-4">
                  <input
                    className="w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={isSpace}
                    required
                    maxLength={50}
                  />
                  <input
                    className="w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleInputChange(setEmail)}
                    required
                    maxLength={50}
                  />
                  <input
                    className="w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="text"
                    placeholder="Enter your phone (optional)"
                    value={phoneNumber}
                    onChange={isMobile}
                    maxLength={10}
                  />
                  <input
                    className="w-full px-5 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={isPasswordValid}
                    required
                  />
                  {passwordError && (
                    <div className="text-red-500 text-sm text-center">
                      {passwordError}
                    </div>
                  )}
                  <div className="flex space-x-4 relative">
                    <div className="relative">
                      <button
                        type="button"
                        className="px-4 py-4 w-40 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center text-center"
                        onClick={toggleDropdown1}
                      >
                        {selectedOption1 || "Class"}
                        <svg
                          className={`w-4 h-4 ml-2 ${
                            isOpen1 ? "transform rotate-180" : ""
                          }`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                      {isOpen1 && (
                        <ul className="absolute mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                          {loading1 ? (
                            <li className="p-2">Loading...</li>
                          ) : (
                            options1.map((option) => (
                              <li
                                key={option.id}
                                onClick={() => closeDropdown1(option)}
                                className="p-2 cursor-pointer hover:bg-gray-100"
                              >
                                {option.label}
                              </li>
                            ))
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="relative">
                      <button
                        type="button"
                        className="px-4 py-4 w-40 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm inline-flex items-center text-center"
                        onClick={toggleDropdown2}
                      >
                        {selectedOption2 || "School"}
                        <svg
                          className={`w-4 h-4 ml-2 ${
                            isOpen2 ? "transform rotate-180" : ""
                          }`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                      {isOpen2 && (
                        <ul className="absolute mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                          {loading2 ? (
                            <li className="p-2">Loading...</li>
                          ) : (
                            options2.map((option) => (
                              <li
                                key={option.id}
                                onClick={() => closeDropdown2(option)}
                                className="p-2 cursor-pointer hover:bg-gray-100"
                              >
                                {option.label}
                              </li>
                            ))
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  {formError && (
                    <div className="text-red-500 text-sm mt-2 text-center">
                      {formError}
                    </div>
                  )}
                  <p className="text-[12px] text-gray-500">
                    * Phone Number is Optional Here. <br />* School Name if not
                    mentioned can be left blank (optional)
                  </p>
                  <button
                    type="submit"
                    className={`mt-5 tracking-wide font-semibold bg-blue-900 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${
                      isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please Wait..." : "Sign Up"}
                  </button>
                  {showSuccessPopup && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
                      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center justify-center">
                        <h2 className="text-lg font-bold mb-4">Success!</h2>
                          <p className="text-center mb-4">Thank you for signing up!<br /> Please check your email inbox to verify your account.</p>
                          <button
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            onClick={() => setShowSuccessPopup(false)}
                          >
                            Close
                          </button>
                      </div>
                    </div>
                  )}  
                  <p className="text-sm text-gray-600 mt-6 text-center">
                    Already have an account?{" "}
                    <NavLink
                      to="/login"
                      className="text-blue-500 font-medium hover:underline"
                    >
                      Log in
                    </NavLink>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
