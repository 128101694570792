import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getTokenFromCookies,
  removeTokenFromCookies,
} from "../utils/cookieUtils";

export interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  logout: () => void;
}

// Initialize AuthContext with `null` as the default value
const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = getTokenFromCookies();
    console.log("Initial token:", token);
    setIsAuthenticated(!!token); // If token exists, set isAuthenticated to true
  }, []);

  const logout = () => {
    removeTokenFromCookies();
    setIsAuthenticated(false);
    window.location.href = "/login"; // Redirect to login on logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  
  return context;
};
