import { Card, CardBody, Button, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import axios from "../../config/axiosConfig";

const ProfileCard: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [profile, setProfile] = useState({
    Name: "John Doe",
    email: "john.doe@example.com",
    phoneNumber: "+1234567890",
    registrationDate: "2024-01-01",
    class: "12345",
    school: "67890",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/users");
        const userData = response.data;

        console.log(userData);

        setProfile({
          Name: userData.name || "John Doe",
          email: userData.email || "john.doe@example.com",
          phoneNumber: userData.phoneNumber || "+1234567890",
          registrationDate: userData.registrationDate || "2024-01-01",
          class: userData.class || "12345",
          school: userData.school || "67890",
        });

        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        setError("Failed to fetch user data");
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchUserData(); // Call the function
  }, []); // Empty dependency array means this will run once on component mount

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  return (
    <Card className="max-w-xl w-full">
      <CardBody className="p-6">
        <div className="flex items-center justify-center mb-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            id="profile"
            width="50"
            height="50"
          >
            <path d="M11.78,11.28A4.462,4.462,0,0,1,16,6.61a4.462,4.462,0,0,1,4.22,4.67A4.45912,4.45912,0,0,1,16,15.94,4.45912,4.45912,0,0,1,11.78,11.28ZM30.04,16a13.91894,13.91894,0,0,1-2.39,7.82,1.43134,1.43134,0,0,1-.14.2,14.01332,14.01332,0,0,1-23.02,0,1.43134,1.43134,0,0,1-.14-.2A14.03633,14.03633,0,1,1,30.04,16ZM3.46,16a12.51091,12.51091,0,0,0,1.57,6.09C7.2,19.24,11.36,17.46,16,17.46s8.8,1.78,10.97,4.63A12.543,12.543,0,1,0,3.46,16Z"></path>
          </svg>
        </div>
        <div className="mt-6">
          <h1 className="text-2xl font-bold mb-6 text-center">
            Profile Information
          </h1>
          <div className="space-y-4">
            <div className="flex justify-between items-center whitespace-nowrap">
              <Input
                name="Name"
                label="Full Name"
                value={profile.Name}
                disabled={!isEditing} // Use input for view mode, disabled if not editing
                onChange={handleChange}
                className="pl-12 ml-12 text-m placeholder:text-m w-96"
              />
            </div>

            <div className="flex justify-between">
              <Input
                name="email"
                label="E-mail"
                isReadOnly
                value={profile.email}
                disabled={!isEditing} // Disable when not editing
                onChange={handleChange}
                className="pl-12 ml-12 text-m placeholder:text-m w-96"
              />
            </div>
            <div className="flex justify-between items-center whitespace-nowrap">
              <Input
                name="phoneNumber"
                label="Phone Number"
                value={profile.phoneNumber}
                disabled={!isEditing} // Disable when not editing
                onChange={handleChange}
                className="pl-12 ml-12 text-m placeholder:text-m w-96"
              />
            </div>
            <div className="flex justify-between items-center whitespace-nowrap">
              <Input
                name="registrationDate"
                label="Registration Date"
                isReadOnly
                value={profile.registrationDate}
                disabled={!isEditing} // Disable when not editing
                onChange={handleChange}
                className="pl-12 ml-12 text-m placeholder:text-m w-96"
              />
            </div>
            <div className="flex justify-between">
              <Input
                name="class"
                label="Class"
                value={profile.class}
                disabled={!isEditing} // Disable when not editing
                onChange={handleChange}
                className="pl-12 ml-12 text-m placeholder:text-m w-96"
              />
            </div>
            <div className="flex justify-between">
              <Input
                name="school"
                label="School"
                value={profile.school}
                disabled={!isEditing} // Disable when not editing
                onChange={handleChange}
                className="pl-12 ml-12 text-m placeholder:text-m w-96"
              />
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <Button
              onClick={handleEditClick}
              style={{
                backgroundColor: "#006FEE",
                color: "white",
              }}
              className="hover:bg-blue-700"
            >
              {isEditing ? "Update Profile" : "Edit Profile"}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProfileCard;
