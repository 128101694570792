import React from "react";
import { Card, CardFooter, Image, Button } from "@nextui-org/react";
import { ObjectCardProps } from "../../data/TopicProps";
import { useNavigate } from "react-router-dom";

const ObjectCard: React.FC<ObjectCardProps> = ({ object }) => {
  const navigate = useNavigate();
  const handleViewObject = () => {
    navigate(`/view?i=${object.objectId}`);
  };
  return (
    <Card isFooterBlurred radius="lg" className="border-none max-w-[200px]">
      <Image
        alt="Woman listening to music"
        className="object-contain"
        height={200}
        src="https://nextui.org/images/hero-card.jpeg"
        width={200}
      />
      <CardFooter className="justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
        <p className="text-tiny text-white/80">{object.objectName}</p>
        <Button
          className="text-tiny text-white bg-black/20"
          variant="flat"
          color="default"
          radius="lg"
          size="sm"
          onClick={handleViewObject}
        >
          View Object
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ObjectCard;
