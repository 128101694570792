import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}> {/* Added Flexbox styles here */}
      <main style={{ flex: 1 }}> {/* Main content area that takes up available space */}
        {/* Your main content goes here */}
      </main>
      <Footer />
    </div>
  );
};

const Footer: React.FC = () => {
  return (
    <footer style={{ padding: '80px', backgroundColor: '#282c34', color: '#fff', marginTop: 'auto' }}> {/* Added marginTop: 'auto' */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ textAlign: 'left' }}>
          <h3 style={{ fontWeight: 'bold', marginBottom:'20px' }}>Follow us</h3>
          <div style={{ display: 'flex', gap: '15px' }}>
            <a href="https://www.instagram.com/edorbit.in/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="https://www.linkedin.com/company/edorbit/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a href="https://www.youtube.com/channel/UCTBb8q9lRcO54QiiRnawvxA" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
            <a href="https://twitter.com/edorbit" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
          </div>
        </div>

        <div style={{ textAlign: 'left' }}>
          <h3 style={{ fontWeight: 'bold', marginBottom:'20px' }}>Links</h3>
          <nav>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li style={{ color: '#ccc' }}><Link to="/">Home</Link></li>
              <li style={{ color: '#ccc' }}><Link to="/settings">Settings</Link></li>
              <li style={{ color: '#ccc' }}><Link to="/login">Login</Link></li>
              <li style={{ color: '#ccc' }}><Link to="/signup">Signup</Link></li>
            </ul>
          </nav>
        </div>

        <div style={{ textAlign: 'left' }}>
          <h3 style={{ fontWeight: 'bold', marginBottom:'20px' }}>Legal</h3>
          <nav>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li style={{ color: '#ccc' }}><Link to="#">Refund and Cancellation</Link></li>
              <li style={{ color: '#ccc' }}><Link to="#">Privacy Policy</Link></li>
              <li style={{ color: '#ccc' }}><Link to="#">Terms and Conditions</Link></li>
              <li style={{ color: '#ccc' }}><Link to="#">Shipping Policy</Link></li>
            </ul>
          </nav>
        </div>
      </div>

      <hr style={{ margin: '20px 0', borderColor: '#fff' }} />
      <p style={{ textAlign: 'center' }}>© 2024 edorbit. All rights reserved.</p>
    </footer>
  );
};

export default App; // Exporting App component which includes Footer