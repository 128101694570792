import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const VerifiedAccountPage: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const handleButtonClick = (path: string) => {
    navigate(path); // Navigate to the specified path
  };

  // State to manage hover effect
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // Full viewport height
        backgroundColor: '#6FD0C5', // Background color for the entire page
        color: '#fff',
        textAlign: 'center',
        padding: 0,
        margin: 0,
      }}
    >
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {/* Centering content vertically and horizontally */}
        <h1
          style={{
            fontSize: '2rem',
            marginBottom: '50px',
          }}
        >
          Your account has been verified.<br />Thank You for joining us <br />
        </h1>
        <div style={{ display: 'flex', gap: '20px' }}>
          {['/', '/explore', '/settings'].map((path, index) => (
            <button
              key={index}
              style={{
                padding: '15px 30px',
                fontSize: '1rem',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                backgroundColor: hoveredButton === path ? '#e0e0e0' : '#fff', // Change background on hover
                color: '#6FD0C5',
                transition: 'background-color 0.3s, box-shadow 0.3s',
                boxShadow: hoveredButton === path ? '0 4px 10px rgba(0, 0, 0, 0.3)' : 'none', // Shadow effect
              }}
              onMouseEnter={() => setHoveredButton(path)} // Set hover state on mouse enter
              onMouseLeave={() => setHoveredButton(null)} // Reset hover state on mouse leave
              onClick={() => handleButtonClick(path)}
              type="button"
            >
              {path === '/' ? 'Home' : path === '/explore' ? 'Explore' : 'Settings'}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerifiedAccountPage;