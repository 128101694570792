import React from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button } from "@nextui-org/react";

import { Switch } from "@nextui-org/react";
import { SunIcon, MoonIcon } from "@heroicons/react/solid";
import { Link as RouterLink } from "react-router-dom";

interface Menu {
  name: string;
  path: string;
}

interface MobilenavbarProps {
  menus: Menu[];
  isAuthenticated: boolean | undefined;
  userName: string;
  logout: (() => void) | undefined;
  theme: string | undefined;
  handleChange: () => void;
  checked: boolean;
}

const Mobilenavbar: React.FC<MobilenavbarProps> = ({
  menus,
  isAuthenticated,
  userName,
  logout,
  theme,
  handleChange,
  checked
}) => {
  return (
    <Navbar>
      <NavbarContent>
        <NavbarMenuToggle
          aria-label="Open menu"
          className="sm:hidden"
        />
        <NavbarBrand>
          
          <p className="font-bold text-inherit ml-2">edorbit</p>
        </NavbarBrand>
      </NavbarContent>

      <NavbarMenu>
        {menus.map((item, index) => (
          <NavbarMenuItem key={`${item.name}-${index}`}>
            <Link
              color={
                index === 2 ? "primary" : index === menus.length - 1 ? "danger" : "foreground"
              }
              className="w-full"
              href={item.path}
              size="lg"
            >
              {item.name}
            </Link>
          </NavbarMenuItem>
        ))}
        {!isAuthenticated ? (
          <>
            <NavbarMenuItem>
              <Link color="primary" href="/login" className="w-full">
                Login
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link color="primary" href="/signup" className="w-full">
                Signup
              </Link>
            </NavbarMenuItem>
          </>
        ) : (
          <>
            <NavbarMenuItem>
              <Link color="foreground" href="#" className="w-full" onClick={logout}>
                Log Out
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <span className="font-semibold">{userName}</span>
            </NavbarMenuItem>
          </>
        )}
      </NavbarMenu>
      <NavbarContent justify="end">
        <Switch
          size="sm"
          isSelected={checked}
          onChange={handleChange}
          startContent={<MoonIcon />}
          endContent={<SunIcon />}
        />
      </NavbarContent>
    </Navbar>
  );
};

export default Mobilenavbar;
