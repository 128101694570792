import axios from "../config/axiosConfig";

export const login = async (emailMobile: string, password: string) => {
  try {
    const response = await axios.post("/auth/login", {
      email: emailMobile,
      password,
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(
        error.response.data?.message ||
          "Something went wrong. Please try again."
      );
    }
    throw new Error("Something went wrong. Please try again.");
  }
};
